import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

//import { FirebaseProvider } from "./contexts/FirebaseContext";
//import { JWTProvider } from "./contexts/JWTContext";
import { Auth0Provider } from "./contexts/Auth0Context";

import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";
import configData from './config/config.json';

const App = () => {
  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <Auth0Provider
          domain={configData.REACT_APP_AUTH0_DOMAIN}
          clientId={configData.REACT_APP_AUTH0_CLIENTID}
          redirectUri={configData.REACT_APP_LOGIN_REDIRECT}
          audience={configData.REACT_APP_AUTH0_AUDIENCE}
        >
          {renderRoutes(routes)}
        </Auth0Provider>
      </Router>
    </React.Fragment>
  );
};

export default App;
