const menuItems = {
    items: [
        {
            id: "navigation",
            title: "Main",
            type: "group",
            icon: "icon-navigation",
            children: [
                {
                    id: "dashboard",
                    title: "Dashboard",
                    type: "collapse",
                    icon: "feather icon-home",
                    children: [
                        {
                            id: "skyline-dashboard",
                            title: "Skyline",
                            type: "item",
                            url: "/app/dashboard/analytics",
                            breadcrumbs: false,
                        },
                        {
                            id: "dealer-dashboard",
                            title: "Dealer",
                            type: "item",
                            url: "/app/dashboard/analytics",
                            breadcrumbs: false,
                        },
                        {
                            id: "client-dashboard",
                            title: "Client",
                            type: "item",
                            url: "/app/dashboard/analytics",
                            breadcrumbs: false,
                        },
                        {
                            id: "sales-dashboard",
                            title: "Sales",
                            type: "item",
                            url: "/app/dashboard/sales",
                            badge: {
                                title: "Request Pending",
                                type: "badge-danger",
                            },
                        },
                    ],
                },
                {
                    id: "admin",
                    title: "Admin",
                    type: "collapse",
                    icon: "feather icon-gitlab",
                    children: [
                        {
                            id: "admin-comm",
                            title: "Comm Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "communication.Message",
                                    title: "Messages",
                                    type: "item",
                                    url: "/admin/communication/message",
                                },
                            ],
                        },
                        {
                            id: "admin-event",
                            title: "Event Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "event.Show",
                                    title: "Shows",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                            ],
                        },
                        {
                            id: "admin-finance",
                            title: "Finance Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "event.Show",
                                    title: "Shows",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                            ],
                        },
                        {
                            id: "admin-global",
                            title: "Global Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "global.Category",
                                    title: "Category",
                                    type: "item",
                                    url: "/admin/global/category",
                                },
                                {
                                    id: "global.Comment",
                                    title: "Comment",
                                    type: "item",
                                    url: "/admin/global/comment",
                                },
                                {
                                    id: "global.CommentType",
                                    title: "CommentType",
                                    type: "item",
                                    url: "/admin/global/commentType",
                                },
                                {
                                    id: "global.Contact",
                                    title: "Contact",
                                    type: "item",
                                    url: "/admin/global/contact",
                                },
                                {
                                    id: "global.ContactType",
                                    title: "Contact Type",
                                    type: "item",
                                    url: "/admin/global/contactType",
                                },
                                {
                                    id: "global.Language",
                                    title: "Language",
                                    type: "item",
                                    url: "/admin/global/language",
                                },
                                {
                                    id: "global.MediaType",
                                    title: "Media Type",
                                    type: "item",
                                    url: "/admin/global/mediaType",
                                },
                                {
                                    id: "global.MediaUsage",
                                    title: "Media Usage",
                                    type: "item",
                                    url: "/admin/global/mediaUsage",
                                },
                                {
                                    id: "global.Property",
                                    title: "Property",
                                    type: "item",
                                    url: "/admin/global/property",
                                },
                                {
                                    id: "global.PropertyType",
                                    title: "PropertyType",
                                    type: "item",
                                    url: "/admin/global/propertyType",
                                },
                                {
                                    id: "global.Scope",
                                    title: "Scope",
                                    type: "item",
                                    url: "/admin/global/scope",
                                },
                                {
                                    id: "global.ShowType",
                                    title: "Show Type",
                                    type: "item",
                                    url: "/admin/global/showType",
                                },
                                {
                                    id: "global.Status",
                                    title: "Status",
                                    type: "item",
                                    url: "/admin/global/status",
                                },
                                {
                                    id: "global.StatusType",
                                    title: "Status Type",
                                    type: "item",
                                    url: "/admin/global/statusType",
                                },
                                {
                                    id: "global.UnitOfMeasure",
                                    title: "Unit of Measure",
                                    type: "item",
                                    url: "/admin/global/unitOfMeasure",
                                },
                            ],
                        },
                        {
                            id: "admin-item",
                            title: "Item Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "event.Show",
                                    title: "Shows",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                            ],
                        },
                        {
                            id: "admin-location",
                            title: "Location Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "location.Country",
                                    title: "Country",
                                    type: "item",
                                    url: "/admin/location/country",
                                },
                                {
                                    id: "location.State",
                                    title: "State",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                                {
                                    id: "location.City",
                                    title: "City",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                            ],
                        },
                        {
                            id: "admin-log",
                            title: "Logging Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "event.Show",
                                    title: "Shows",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                            ],
                        },
                        {
                            id: "admin-org",
                            title: "Org Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "event.Show",
                                    title: "Shows",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                            ],
                        },
                        {
                            id: "admin-project",
                            title: "Project Module",
                            type: "collapse",
                            children: [
                                {
                                    id: "event.Show",
                                    title: "Shows",
                                    type: "item",
                                    url: "/admin/event/show",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "sales",
                    title: "Sales",
                    type: "collapse",
                    icon: "feather icon-help-circle",
                    children: [
                        {
                            id: "pc-dashboard",
                            title: "Dashboard",
                            type: "item",
                            url: "/project-crm/pc-dashboard",
                        },
                        {
                            id: "hd-customer-list",
                            title: "Customer",
                            type: "item",
                            url: "/helpdesk/hd-customer-list",
                        },
                    ],
                },
                {
                    id: "support",
                    title: "Support",
                    type: "collapse",
                    icon: "feather icon-help-circle",
                    children: [
                        {
                            id: "hd-dashboard",
                            title: "Dashboard",
                            type: "item",
                            url: "/helpdesk/hd-dashboard",
                        },
                        {
                            id: "hd-ticket",
                            title: "Create Ticket",
                            type: "item",
                            url: "/helpdesk/hd-ticket",
                        },
                        {
                            id: "hd-ticket-details",
                            title: "Ticket Detail",
                            type: "item",
                            url: "/helpdesk/hd-ticket-details",
                        },
                    ],
                },
            ],
        },
        {
            id: "org",
            title: "Organizations",
            type: "group",
            icon: "icon-monitor",
            children: [
                {
                    id: "org-entity",
                    title: "Companies",
                    type: "collapse",
                    icon: "feather icon-layers",
                    badge: {
                        title: "100+",
                        type: "badge-info",
                    },
                    children: [
                        {
                            id: "org-entity-vendor",
                            title: "Vendors",
                            type: "item",
                            url: "/users/user-list",
                        },
                        {
                            id: "org-entity-dealer",
                            title: "Dealers",
                            type: "item",
                            url: "/users/user-list",
                        },
                        {
                            id: "org-entity-employee",
                            title: "Employees",
                            type: "item",
                            url: "/users/user-list",
                        },
                        {
                            id: "org-entity-freight",
                            title: "Freight Companies",
                            type: "item",
                            url: "/users/user-list",
                        },
                        {
                            id: "org-entity-agency",
                            title: "Agencies",
                            type: "item",
                            url: "/users/user-list",
                        },
                        {
                            id: "org-permission",
                            title: "Permissions",
                            type: "item",
                            url: "/users/user-list",
                        },
                        {
                            id: "org-role",
                            title: "Roles",
                            type: "item",
                            url: "/users/user-list",
                        },
                    ],
                },
                {
                    id: "org-entity-person",
                    title: "People",
                    type: "collapse",
                    icon: "feather icon-layers",
                    badge: {
                        title: "100+",
                        type: "badge-info",
                    },
                    children: [
                        {
                            id: "org-person",
                            title: "Users",
                            type: "item",
                            url: "/users/user-list",
                        },
                    ],
                },
            ],
        },
        {
            id: "inventory",
            title: "Inventory",
            type: "group",
            icon: "icon-monitor",
            children: [
                {
                    id: "inventory-location",
                    title: "Locations",
                    type: "collapse",
                    icon: "feather icon-layers",
                    badge: {
                        title: "25",
                        type: "badge-info",
                    },
                    children: [
                        {
                            id: "inventory-location-address",
                            title: "Addresses",
                            type: "item",
                            url: "/admin/geographic/locations",
                        },
                        {
                            id: "buildings",
                            title: "Buildings",
                            type: "item",
                            url: "/admin/geographic/buildings",
                        },
                        {
                            id: "countries",
                            title: "Countries",
                            type: "item",
                            url: "/admin/geographic/countries",
                        },
                    ],
                },
                {
                    id: "inventory-container",
                    title: "Containers",
                    type: "collapse",
                    icon: "feather icon-layers",
                    badge: {
                        title: "25",
                        type: "badge-info",
                    },
                    children: [
                        {
                            id: "inventory-container-crate",
                            title: "Crates",
                            type: "item",
                            url: "/advance/nestable",
                        },
                        {
                            id: "inventory-container-bin",
                            title: "Bins",
                            type: "item",
                            url: "/advance/nestable",
                        },
                    ],
                },
                {
                    id: "inventory-item",
                    title: "Items",
                    type: "collapse",
                    icon: "feather icon-layers",
                    badge: {
                        title: "25",
                        type: "badge-info",
                    },
                    children: [
                        {
                            id: "inventory-item-exhibit",
                            title: "Exhibits",
                            type: "item",
                            url: "/ecommerce/ecomm-product",
                        },
                        {
                            id: "inventory-item-accessory",
                            title: "Accessories",
                            type: "item",
                            url: "/ecommerce/ecomm-product",
                        },
                        {
                            id: "inventory-item-consumable",
                            title: "Consumables",
                            type: "item",
                            url: "/ecommerce/ecomm-product",
                        },
                    ],
                },
            ],
        },
        {
            id: "project",
            title: "Projects",
            type: "group",
            icon: "icon-monitor",
            children: [
                {
                    id: "projects",
                    title: "Projects",
                    type: "collapse",
                    icon: "feather icon-layers",
                    badge: {
                        title: "100+",
                        type: "badge-info",
                    },
                    children: [
                        {
                            id: "pc-project",
                            title: "Projects",
                            type: "item",
                            url: "/project-crm/pc-project",
                        },
                        {
                            id: "hd-customer-list",
                            title: "Jobs",
                            type: "item",
                            url: "/helpdesk/hd-customer-list",
                        },
                        {
                            id: "pc-task",
                            title: "Task",
                            type: "item",
                            url: "/project-crm/pc-task",
                        },
                        {
                            id: "task-board",
                            title: "Task Board",
                            type: "item",
                            url: "/advance/task-board",
                        },
                        {
                            id: "task-detail",
                            title: "Task Detail",
                            type: "item",
                            url: "/task/task-detail",
                        },
                        {
                            id: "task-list",
                            title: "Task List",
                            type: "item",
                            url: "/task/task-list",
                        },
                        {
                            id: "mshp-email",
                            title: "Job Quote Templates",
                            type: "item",
                            url: "/membership/mshp-email",
                        },
                        {
                            id: "mshp-newsletter",
                            title: "Add Quote",
                            type: "item",
                            url: "/membership/mshp-newsletter",
                        },
                    ],
                },
            ],
        },
        {
            id: "finance",
            title: "Finance",
            type: "group",
            icon: "icon-monitor",
            children: [
                {
                    id: "accountsReceivable",
                    title: "Receivables",
                    type: "collapse",
                    icon: "feather icon-layers",
                    badge: {
                        title: "100+",
                        type: "badge-info",
                    },
                    children: [
                        {
                            id: "invoice-list",
                            title: "Invoice List",
                            type: "item",
                            url: "/invoice/invoice-list",
                        },
                        {
                            id: "invoice-basic",
                            title: "Invoice Basic",
                            type: "item",
                            url: "/invoice/invoice-basic",
                        },
                        {
                            id: "invoice-summary",
                            title: "Invoice Summary",
                            type: "item",
                            url: "/invoice/invoice-summary",
                        },
                    ],
                },
            ],
        },
        {
            id: "security",
            title: "Security",
            type: "group",
            icon: "icon-pages",
            children: [
                {
                    id: "authentication",
                    title: "Authentication",
                    type: "collapse",
                    icon: "feather icon-lock",
                    children: [
                        {
                            id: "users",
                            title: "Users",
                            type: "item",
                            url: "/users/user-list",
                        },
                        {
                            id: "signup-2",
                            title: "Sign up",
                            type: "item",
                            url: "/auth/signup-2",
                            target: true,
                            breadcrumbs: false,
                        },
                        {
                            id: "signin-2",
                            title: "Sign In",
                            type: "item",
                            url: "/auth/signin-2",
                            target: true,
                            breadcrumbs: false,
                        },
                        {
                            id: "reset-password-2",
                            title: "Reset Password",
                            type: "item",
                            url: "/auth/reset-password-2",
                            target: true,
                            breadcrumbs: false,
                        },
                        {
                            id: "offline-ui",
                            title: "Take EP Offline",
                            type: "item",
                            url: "/maintenance/offline-ui",
                            target: true,
                            breadcrumbs: false,
                        },
                    ],
                },
            ],
        },

        {
            id: "examples",
            title: "Examples",
            type: "group",
            icon: "icon-pages",
            children: [
                {
                    id: "user-profile",
                    title: "Profile",
                    type: "item",
                    url: "/users/user-profile",
                },
                {
                    id: "mshp-user",
                    title: "Employees",
                    type: "item",
                    url: "/membership/mshp-user",
                },
                {
                    id: "pc-customers",
                    title: "Clients",
                    type: "item",
                    url: "/project-crm/pc-customers",
                },
                {
                    id: "sis-leave",
                    title: "Leaves",
                    type: "item",
                    url: "/sis/sis-leave",
                },
                {
                    id: "show",
                    title: "Shows",
                    type: "item",
                    url: "/sis/show",
                },
                {
                    id: "full-calendar",
                    title: "Full Calendar",
                    type: "item",
                    url: "/full-calendar",
                },
                {
                    id: "mshp-country",
                    title: "Country",
                    type: "item",
                    url: "/membership/mshp-country",
                },
                {
                    id: "user-cards",
                    title: "User Card",
                    type: "item",
                    url: "/users/user-cards",
                },
                {
                    id: "messaging",
                    title: "Messaging",
                    type: "item",
                    url: "/helpdesk/hd-ticket-list",
                },
                {
                    id: "hd-customer-list",
                    title: "Customer",
                    type: "item",
                    url: "/helpdesk/hd-customer-list",
                },
                {
                    id: "photo-grid",
                    title: "Gallery",
                    type: "item",
                    url: "/gallery/photo-grid",
                },
                {
                    id: "image-cropper",
                    title: "Image Cropper",
                    type: "item",
                    url: "/image-cropper",
                },
                {
                    id: "graphics",
                    title: "Graphics",
                    type: "item",
                    url: "/advance/tree-view",
                },
            ],
        },
    ],
};

export default menuItems;
