import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { exhibitApi } from '../services/exhibitApi';

import reducers from './reducers';

const store = configureStore({
    reducer: reducers,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(exhibitApi.middleware),
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

setupListeners(store.dispatch);
export default store;
