export const SHOW_TAG = "show";
export const SHOW_ENTITY_TAG = "showEntity";
export const SHOW_ENTITY_TYPE_TAG = "showEntityType";
export const SHOW_CONTACT_TAG = "showContact";
export const SHOW_EVENT_TAG = "showEvent";
export const SHOW_INTERVAL_TAG = "showInterval";
export const ENTITY_TAG = "entity";
export const ENTITY_TYPE_TAG = "entity";
export const ENTITY_CONTACT_TAG = "entityContact";
export const EVENT_TAG = "event";
export const MESSAGE_TAG = "message";
export const GLOBAL_SHOW_TYPE_TAG = "showType";
export const GLOBAL_LANGUAGE_TAG = "language";
export const GLOBAL_CATEGORY_TAG = "Category";
export const GLOBAL_SERIES_TAG = "Series";
export const GLOBAL_ADDRESS_TAG = "Address";
export const GLOBAL_STATUS_TAG = "Status";
export const GLOBAL_STATUS_TYPE_TAG = "StatusType";
export const GLOBAL_COMMENT_TAG = "Comment";
export const GLOBAL_COMMENT_TYPE_TAG = "CommentType";
export const GLOBAL_CONTACT_TAG = "Contact";
export const GLOBAL_CONTACT_TYPE_TAG = "ContactType";
export const GLOBAL_MEDIA_USAGE_TAG = "MediaUsage";
export const GLOBAL_MEDIA_TYPE_TAG = "MediaType";
export const GLOBAL_PROPERTY_TAG = "Property";
export const GLOBAL_PROPERTY_TYPE_TAG = "PropertyType";
export const GLOBAL_SCOPE_TAG = "Scope";
export const GLOBAL_UNIT_OF_MEASURE_TAG = "UnitOfMeasure";
export const USER_TAG = "User";
export const LOCATION_COUNTRY_TAG = "Country";

export const TagType = [
    SHOW_TAG,
    SHOW_ENTITY_TAG,
    SHOW_ENTITY_TYPE_TAG,
    SHOW_CONTACT_TAG,
    SHOW_EVENT_TAG,
    SHOW_INTERVAL_TAG,
    ENTITY_TYPE_TAG,
    ENTITY_CONTACT_TAG,
    EVENT_TAG,
    MESSAGE_TAG,
    GLOBAL_SHOW_TYPE_TAG,
    GLOBAL_LANGUAGE_TAG,
    GLOBAL_CATEGORY_TAG,
    GLOBAL_SERIES_TAG,
    GLOBAL_ADDRESS_TAG,
    GLOBAL_STATUS_TAG,
    GLOBAL_STATUS_TYPE_TAG,
    GLOBAL_COMMENT_TAG,
    GLOBAL_COMMENT_TYPE_TAG,
    GLOBAL_CONTACT_TAG,
    GLOBAL_CONTACT_TYPE_TAG,
    GLOBAL_MEDIA_USAGE_TAG,
    GLOBAL_MEDIA_TYPE_TAG,
    GLOBAL_PROPERTY_TAG,
    GLOBAL_PROPERTY_TYPE_TAG,
    GLOBAL_SCOPE_TAG,
    GLOBAL_UNIT_OF_MEASURE_TAG,
    USER_TAG,
    LOCATION_COUNTRY_TAG,
];
