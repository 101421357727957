import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import { exhibitApi } from "../services/exhibitApi";

const reducers = combineReducers({
    form: formReducer,
    [exhibitApi.reducerPath]: exhibitApi.reducer,
});

export default reducers;
