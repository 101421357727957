import React, { createContext, useEffect, useReducer, useState } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import Cookies from "universal-cookie";

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from "../store/actions";
import { CONFIG } from "../config/constant";
import Loader from "../components/Loader/Loader";
import accountReducer from "../store/accountReducer";

import { useAddRecordMutation } from "../services/exhibitApi";
import { USER_TAG } from "../common/tagFile";
import { successToast, errorToast } from "common/toast";

let auth0Server = null;

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};

const Auth0Context = createContext({
  ...initialState,
  popupLogin: () => Promise.resolve(),
  logout: () => { }
});

export const Auth0Provider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const [addNewUser] = useAddRecordMutation();
  const [userData, setUserData] = useState();

  const popupLogin = async (options) => {
    await auth0Server.loginWithPopup(options);
    const isLoggedIn = await auth0Server.isAuthenticated();

    if (isLoggedIn) {
      const user = await auth0Server.getUser();
      var accessToken = await auth0Server.getTokenSilently();
      const cookies = new Cookies();
      cookies.set("accessToken", accessToken);
      try {
        addNewUser({ entity: "User/CreateUser", data: { SsoIdentifier: user.sub, Name: user.name, Email: user.email, Description: "" }, tag: USER_TAG })
          .unwrap()
          .then(response => {
            localStorage.setItem("user", JSON.stringify(response));
            setUserData(response);
            if (!response.email)
              successToast("User added successfully");
          })
          .catch(error => {
            errorToast("Error occured in Adding User");
          });
      } catch (error) {
        successToast("Something went wrong.");
      }
      dispatch({
        type: LOGIN,
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            tier: 'Premium'
          }
        }
      });
    }
  };

  const logout = () => {
    auth0Server.logout();
    localStorage.removeItem("user");
    dispatch({
      type: LOGOUT
    });
  };

  useEffect(() => {
    const init = async () => {
      try {
        auth0Server = new Auth0Client({
          redirect_uri: window.location.origin,
          ...CONFIG.auth0
        });

        await auth0Server.checkSession();

        const isLoggedIn = await auth0Server.isAuthenticated();

        if (isLoggedIn) {
          var accessToken = await auth0Server.getTokenSilently();
          const cookies = new Cookies();
          cookies.set("accessToken", accessToken);
          const user = await auth0Server.getUser();
          console.log(user)

          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user: {
                id: user.sub,
                name: user.name,
                email: user.email,
                picture: user.picture,
              }
            }
          });
        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <Auth0Context.Provider
      value={{ ...state, popupLogin, logout, userData }} >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Context;