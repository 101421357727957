import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { TagType } from "../common/tagFile";
import Cookies from 'universal-cookie';
import configData from 'config/config.json';

const expandChildQuery = (expand) => {
    return "$expand=" + Object.entries(expand).map(
        (o) => `${o[0]}
            ${o[1].fields
                    ? `($select=${o[1].fields}${o[1].expand
                        ? expandChildQuery(o[1].expand)
                        : ""})`
                    : `${o[1].expand
                        ? `(${expandChildQuery(o[1].expand)})`
                        : ""}`}`
    ).join(",");
};

export const exhibitApi = createApi({
    reducerPath: "exhibitApi",
    tagTypes: TagType,
    baseQuery: retry(fetchBaseQuery({
        baseUrl: configData.REACT_APP_API_URL,
        prepareHeaders: (headers) => {
            const cookies = new Cookies()
            const token = cookies.get('accessToken')
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
            return headers
          },
    }), {
        maxRetries: 3,
    }),
    endpoints: (builder) => ({
        getRecords: builder.query({
            query: ({
                entity,
                columns,
                page = 10,
                pageSize,
                sort = { sortDirection: "none" },
                columnFilter,
                globalFilter,
                extraFilter = "",
                expand = {}
            }) => {
                var skip = (page - 1) * pageSize;
                var top = pageSize;
                var sortUrlString =
                    sort.sortDirection !== "none"
                        ? `&$orderby=${sort.accessor.includes('.') ? sort.accessor.replace('.', '/') : sort.accessor} ${sort.sortDirection}`
                        : `&$orderby=Id desc`;

                var filterForAllColumns = { ...columnFilter };

                if (globalFilter) {
                    columns.forEach((column) => {
                        if (
                            !column.disableFilters &&
                            !filterForAllColumns[column.accessor]
                        ) {
                            if (!(column.contentType === "Numeric" && isNaN(globalFilter))) {
                                filterForAllColumns[column.accessor] = globalFilter;
                            }
                        }
                    });
                }

                var filterUrlString = Object.entries(filterForAllColumns)
                    .map(([key, value]) =>
                        columns.find((c) => c.accessor === key).contentType === "Numeric"
                            ? `${key} eq ${value}`
                            : `contains(${key.includes('.') ? key.replace('.', '/') : key},'${value}')`
                    )
                    .join(" Or ");
                filterUrlString = `&$filter=IsDeleted ne true${extraFilter ? ` AND ${extraFilter}` : ""}${filterUrlString ? ` AND ${filterUrlString}` : ""}`;

                var expandQueryStr = "";
                if (Object.entries(expand).length > 0)
                    expandQueryStr = "&" + expandChildQuery(expand);

                return {
                    url: `${entity}?$skip=${skip}&$top=${top}&$count=true${sortUrlString}${filterUrlString}${expandQueryStr}`,
                    method: "GET",
                    headers: {
                        "content-type": `application/json;odata=verbose`,
                    },
                };
            },
            extraOptions: { maxRetries: 0 },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),

        getAllRecords: builder.query({
            query: ({ entity, subEntity }) => {
                return {
                    url: (subEntity === null) ? `${entity}/All` : `${entity}/${subEntity}`,
                    method: "GET",
                    headers: {
                        "content-type": `application/json;odata=verbose`,
                    },
                };
            },
            extraOptions: { maxRetries: 0 },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
        getAllRecord: builder.query({
            query: ({ entity, subEntity = null, query = "" }) => {
                let finalUrl = (subEntity === null) ? entity : `${entity}/${subEntity}`;
                if (query !== "") {
                    finalUrl += `?${query}`;
                }
                return {
                    url: finalUrl,
                    method: "GET",
                    headers: {
                        "content-type": `application/json;odata=verbose`,
                    },
                };
            },
            extraOptions: { maxRetries: 0 },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),

        getRecordById: builder.query({
            query: ({ entity, id, expand = {}, fields = [] }) => {
                const queryStrings = [];
                if (Object.entries(expand).length > 0)
                    queryStrings.push("$expand=" + Object.entries(expand).map((o) => `${o[0]}($select=${o[1]})`).join(","));
                if (fields.length > 0)
                    queryStrings.push("$select=" + fields.join(","));
                return {
                    url: `${entity}/${id}${queryStrings.length > 0 ? "?" + queryStrings.join("&") : ""}`,
                    method: "GET",
                    headers: {
                        "content-type": `application/json;odata=verbose`,
                    },
                };
            },
            extraOptions: { maxRetries: 0 },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),

        getRecordsById: builder.query({
            query: ({ entity, subEntity = null, filter = "", expand = {}, query = '' }) => {
                var expandQueryStr = "";
                if (Object.entries(expand).length > 0)
                    expandQueryStr = "&" + expandChildQuery(expand);
                let finalUrl = subEntity === null ? `${entity}?$count=true` : `${entity}/${subEntity}?$count=true`;
                if (expandQueryStr.length > 0) {
                    finalUrl += expandQueryStr;
                }
                if (filter && filter.length > 0) {
                    finalUrl += `&$filter=${filter}`;
                }
                if (query && query.length > 0) {
                    finalUrl += query;
                }
                return {
                    url: finalUrl,
                    method: "GET",
                    headers: {
                        "content-type": `application/json;odata=verbose`,
                    },
                };
            },
            extraOptions: { maxRetries: 0 },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),

        addRecord: builder.mutation({
            query: (params) => ({
                url: `${params.entity}`,
                method: "POST",
                body: params.data,
            }),
            extraOptions: { maxRetries: 0 },
            invalidatesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),

        updateRecord: builder.mutation({
            query: (params) => ({
                url: `${params.entity}/${params.data.Id}`,
                method: "PUT",
                body: params.data,
            }),
            extraOptions: { maxRetries: 0 },
            invalidatesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),

        deactivateRecord: builder.mutation({
            query: (params) => ({
                url: `${params.entity}/${params.id}`,
                method: "DELETE",
            }),
            extraOptions: { maxRetries: 0 },
            invalidatesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
    }),
});

export const {
    useGetRecordsQuery,
    useLazyGetRecordsQuery,
    useGetAllRecordsQuery,
    useLazyGetAllRecordsQuery,
    useGetAllRecordQuery,
    useLazyGetAllRecordQuery,
    useGetRecordByIdQuery,
    useGetRecordsByIdQuery,
    useLazyGetRecordsByIdQuery,
    useAddRecordMutation,
    useUpdateRecordMutation,
    useDeactivateRecordMutation
} = exhibitApi;